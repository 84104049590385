/* fallback */
@font-face {
    font-family: "Material Icons";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2") format("woff2");
}

/* Verveine */
@font-face {
    font-family: "Verveine";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/Verveine.woff2") format("woff2");
}

/* Cabin */
@font-face {
    font-family: "Cabin";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/Cabin.woff2") format("woff2");
}

/* cyrillic-ext */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYlK-4E4Q.woff2") format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYsK-4E4Q.woff2") format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYnK-4E4Q.woff2") format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYmK-4E4Q.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYoK-4.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYlK-4E4Q.woff2") format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYsK-4E4Q.woff2") format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYnK-4E4Q.woff2") format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYmK-4E4Q.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYoK-4.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYlK-4E4Q.woff2") format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYsK-4E4Q.woff2") format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYnK-4E4Q.woff2") format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYmK-4E4Q.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYoK-4.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYlK-4E4Q.woff2") format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYsK-4E4Q.woff2") format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYnK-4E4Q.woff2") format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYmK-4E4Q.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Exo 2";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/7cHmv4okm5zmbtYoK-4.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpMtKy2OAdR1K-IwhWudF-R3woqauLY1HY.woff2") format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: "Source Sans 3";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpMtKy2OAdR1K-IwhWudF-R3woqY-LY1HY.woff2") format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpMtKy2OAdR1K-IwhWudF-R3woqa-LY1HY.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: "Source Sans 3";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpMtKy2OAdR1K-IwhWudF-R3woqZOLY1HY.woff2") format("woff2");
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: "Source Sans 3";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpMtKy2OAdR1K-IwhWudF-R3woqaOLY1HY.woff2") format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpMtKy2OAdR1K-IwhWudF-R3woqaeLY1HY.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Source Sans 3";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpMtKy2OAdR1K-IwhWudF-R3woqZ-LY.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpMtKy2OAdR1K-IwhWudF-R3woqauLY1HY.woff2") format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: "Source Sans 3";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpMtKy2OAdR1K-IwhWudF-R3woqY-LY1HY.woff2") format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpMtKy2OAdR1K-IwhWudF-R3woqa-LY1HY.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: "Source Sans 3";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpMtKy2OAdR1K-IwhWudF-R3woqZOLY1HY.woff2") format("woff2");
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: "Source Sans 3";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpMtKy2OAdR1K-IwhWudF-R3woqaOLY1HY.woff2") format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpMtKy2OAdR1K-IwhWudF-R3woqaeLY1HY.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Source Sans 3";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpMtKy2OAdR1K-IwhWudF-R3woqZ-LY.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wIaZfrc.woff2") format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wsaZfrc.woff2") format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wMaZfrc.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wwaZfrc.woff2") format("woff2");
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wAaZfrc.woff2") format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wEaZfrc.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3w8aZQ.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wIaZfrc.woff2") format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wsaZfrc.woff2") format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wMaZfrc.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wwaZfrc.woff2") format("woff2");
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wAaZfrc.woff2") format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wEaZfrc.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3w8aZQ.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wIaZfrc.woff2") format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wsaZfrc.woff2") format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wMaZfrc.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wwaZfrc.woff2") format("woff2");
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wAaZfrc.woff2") format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wEaZfrc.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3w8aZQ.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wIaZfrc.woff2") format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wsaZfrc.woff2") format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wMaZfrc.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wwaZfrc.woff2") format("woff2");
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wAaZfrc.woff2") format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wEaZfrc.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3w8aZQ.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wIaZfrc.woff2") format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wsaZfrc.woff2") format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wMaZfrc.woff2") format("woff2");
    unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wwaZfrc.woff2") format("woff2");
    unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wAaZfrc.woff2") format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3wEaZfrc.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: "Source Sans 3";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/nwpStKy2OAdR1K-IwhWudF-R3w8aZQ.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* font Avenir */
@font-face {
    font-family: "Avenir Roman";
    font-display: swap;
    src: url("/assets/fonts/AvenirLTStd-Roman.otf") format("opentype");
}

@font-face {
    font-family: "Avenir Book";
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/AvenirLTStd-Book.otf") format("opentype");
}

@font-face {
    font-family: "Avenir Black";
    font-display: swap;
    src:
        local(""),
        url("/assets/fonts/AvenirLTStd-Black.otf") format("opentype");
}

@font-face {
    font-family: "Fallback font";
    src: local("Helvetica Neue");
    font-size: 16px;
    line-height: 1.6;
    font-weight: 300;
    letter-spacing: 0.6px;
    word-spacing: 0px;
    size-adjust: 97.38%;
    ascent-override: 99%;
}

/* nunito-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/XRXI3I6Li01BKofiOc5wtlZ2di8HDLshdTQ3j6zbXWjgeg.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* The Sally */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "The Sally";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/TheSally.ttf") format("truetype"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on September 16, 2021 */

@font-face {
    font-family: 'tuitype_latnbold';
    src: url('tuitype_latn_w_bd-webfont.woff2') format('woff2'),
         url('tuitype_latn_w_bd-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'tuitype_latnbold_italic';
    src: url('tuitype_latn_w_bdit-webfont.woff2') format('woff2'),
         url('tuitype_latn_w_bdit-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'tuitype_latnitalic';
    src: url('tuitype_latn_w_it-webfont.woff2') format('woff2'),
         url('tuitype_latn_w_it-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'tuitypelight_latnbold';
    src: url('tuitypelt_latn_w_bd-webfont.woff2') format('woff2'),
         url('tuitypelt_latn_w_bd-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'tuitypelight_latnbold_italic';
    src: url('tuitypelt_latn_w_bdit-webfont.woff2') format('woff2'),
         url('tuitypelt_latn_w_bdit-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'tuitypelight_latnitalic';
    src: url('tuitypelt_latn_w_it-webfont.woff2') format('woff2'),
         url('tuitypelt_latn_w_it-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'tuitypelight_latnregular';
    src: url('tuitypelt_latn_w_rg-webfont.woff2') format('woff2'),
         url('tuitypelt_latn_w_rg-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

/* Alexandria */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "Alexandria";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/Alexandria-VariableFont_wght.ttf") format("truetype"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* SourceSans3 */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "SourceSans";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/SourceSans3-VariableFont_wght.ttf") format("truetype"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* SourceSans3-Italic */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: "SourceSans3-Italic";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/SourceSans3-Italic-VariableFont_wght.ttf") format("truetype"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}